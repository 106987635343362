<template>
  <div>
    <b-card-actions
      title="Filters"
      action-collapse
      collapsed
    >
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Start Date"
            label-for="starts_at"
          >
            <div class="position-relative">
              <date-range-picker
                ref="picker"
                class="form-control no-border"
                v-model="filters['start_date']"
                :opens="opens"
                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
                :singleDatePicker="false"
                :timePicker="true"
                :timePicker24Hour="true"
                :showWeekNumbers="true"
                :showDropdowns="true"
                :autoApply="autoApply"
                :linkedCalendars="false"
                :dateFormat="dateFormat"
                @update="updateDateRange('start_date', $event)"
                @toggle="logEvent('event: open', $event)"
                @start-selection="logEvent('event: startSelection', $event)"
                @finish-selection="logEvent('event: finishSelection', $event)"
              >
                <template v-slot:input="{ startDate, endDate }">
                  <div class="date-picker-input">
                    {{ startDate && endDate ? formatDate(startDate) + ' to ' + formatDate(endDate) : 'Select a date range' }}
                  </div>
                  <div
                    v-if="startDate && endDate"
                    class="datepicker-cancel"
                    @click="clearDate('start_date')"
                  >
                    <feather-icon icon="XIcon" />
                  </div>
                </template>
              </date-range-picker>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="End Date"
            label-for="ends_at"
          >
            <div class="position-relative">
              <date-range-picker
                ref="picker"
                v-model="filters['end_date']"
                class="form-control no-border"
                :opens="opens"
                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
                :singleDatePicker="false"
                :timePicker="true"
                :timePicker24Hour="true"
                :showWeekNumbers="true"
                :showDropdowns="true"
                :autoApply="autoApply"
                :linkedCalendars="false"
                :dateFormat="dateFormat"
                @update="updateDateRange('end_date', $event)"
                @toggle="logEvent('event: open', $event)"
                @start-selection="logEvent('event: startSelection', $event)"
                @finish-selection="logEvent('event: finishSelection', $event)"
              >
                <template v-slot:input="{ startDate, endDate }">
                  <div class="date-picker-input">
                    {{ startDate && endDate ? formatDate(startDate) + ' to ' + formatDate(endDate) : 'Select a date range' }}
                  </div>
                  <div
                    v-if="startDate && endDate"
                    class="datepicker-cancel"
                    @click="clearDate('end_date')"
                  >
                    <feather-icon icon="XIcon" />
                  </div>
                </template>
              </date-range-picker>
            </div>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Package"
            label-for="package"
          >
            <div class="position-relative">
              <b-form-input
                id="package_name"
                v-model="filters['package.package_name']"
                placeholder="Enter Package Name"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Subscription Status"
            label-for="subscriptionStatus"
          >
            <div class="position-relative">
              <v-select
                id="subscriptionStatus"
                v-model="filters['subscription_status']"
                :options="subscriptionStatus"
                :reduce="option => option.key"
                placeholder="Select Status"
                autocomplete="nope"
                label="name"
              >
                <template
                  v-slot:option="option"
                >
                  {{ option.name }}
                </template>
              </v-select>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-actions>
    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :to="{ name: 'add-subscription' }"
          variant="outline-primary"
          class="mb-2"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Add New Subscription
        </b-button>
      </b-col>
    </b-row>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="Business Name"
              label-for="business option"
            >
              <v-select
                v-model="filters['tenant.name']"
                :options="clientOptions"
                :reduce="option => option.name"
                placeholder="Select Business"
                label="name"
              >
                <template
                  v-slot:option="option"
                  style="height: 200px"
                >
                  {{ option.name }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            class="d-flex justify-content-end align-items-center mb-0"
          >
            <JsonExcel
              class="btn p-0"
              :data="rows"
              :fields="json_fields"
              type="csv"
              :name="`Subscriptions - Page ${page}.xls`"
            >
              <b-button
                v-b-tooltip.hover.v-primary="'Download Excel - Page'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mr-1"
                size="sm"
              >
                <mdicon
                  name="Download"
                  size="13"
                />
              </b-button>
            </JsonExcel>
            <JsonExcel
              class="btn p-0"
              :fetch="getSubscriptionsNoPagination"
              :before-generate="__showJsonDownloadAlert"
              :fields="json_fields"
              type="csv"
              name="Subscriptions.xls"
            >
              <b-button
                v-b-tooltip.hover.v-primary="'Download Excel - All'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mr-1"
                size="sm"
              >
                <mdicon
                  name="DownloadMultiple"
                  size="15"
                />
              </b-button>
            </JsonExcel>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary="'Refresh Table'"
              variant="outline-primary"
              size="sm"
              @click="onClickRefresh()"
            >
              <feather-icon
                icon="RefreshCwIcon"
              />
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col>
          <b-overlay
            id="overlay-background"
            :show="loading"
            blur="10px"
            opacity="0.30"
            rounded="sm"
            variant="light"
          >
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="rows"
              :fields="fields"
              show-empty
            >
              <template #cell(business_name)="data">
                <div
                  class="text-dark"
                >
                  {{ data.item.tenant.business_name === null ? data.item.tenant.name : data.item.tenant.business_name }}
                </div>
              </template>
              <template #cell(package.package_name)="data">
                <div
                  class="text-primary"
                >
                  {{ data.value }} Package
                </div>
              </template>
              <template #cell(created_at)="data">
                <div>
                  {{ __dateTimeFormatter(data.value) }}
                </div>
              </template>
              <template #cell(start_date)="data">
                <div>
                  {{ __dateTimeFormatter(data.value) }}
                </div>
              </template>
              <template #cell(end_date)="data">
                <div>
                  {{ __dateTimeFormatter(data.value) }}
                </div>
              </template>
              <template #cell(package.order_limit)="data">
                <b-badge :variant="data.value === 'unlimited' ? 'light-success' : ''">
                  {{ data.value === 'unlimited' ? 'Unlimited Orders' : data.value }}
                </b-badge>
              </template>
              <template #cell(subscription_status)="data">
                <b-badge :variant="data.value === 'active' ? 'light-success' : (data.value === 'cancelled' || data.value === 'deactivated') ? 'light-warning' : 'light-danger'">
                  {{ data.value === 'active' ? 'ACTIVE' : (data.value === 'cancelled' ? 'CANCELLED' : (data.value === 'deactivated' ? 'DEACTIVATED' : 'EXPIRED')) }}
                </b-badge>
              </template>
            </b-table>
          </b-overlay>

          <div class="mx-2 mb-2">
            <b-row
              cols="12"
            >
              <b-col
                class="d-flex align-items-center justify-content-sm-start"
                md="3"
              >
                <label class="width-75 mr-1">Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block width-100"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                class="d-flex align-items-center justify-content-sm-end"
                md="9"
              >
                <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="(meta.total)? meta.total : 0"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  // BAvatar,
  BCol,
  BRow,
  BCard,
  BPagination,
  BTable,
  BOverlay,
  BButton,
  VBTooltip,
  BFormGroup,
  BFormInput,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import vSelect from 'vue-select'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import JsonExcel from 'vue-json-excel'
import { avatarText } from '@core/utils/filter'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const SubscriptionRepository = RepositoryFactory.get('subscription')
const clientRepository = RepositoryFactory.get('client')

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    // BAvatar,
    BCol,
    BRow,
    BCard,
    BPagination,
    BTable,
    BOverlay,
    BFormGroup,
    BFormInput,
    BButton,
    BCardActions,
    vSelect,
    JsonExcel,
    DateRangePicker,
    BBadge,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      avatarText,
      total: 0,
      meta: {},
      loading: false,
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      filters: {
        start_date: {
          startDate: null,
          endDate: null,
        },
        end_date: {
          startDate: null,
          endDate: null,
        },
      },
      options: {
        delimiter: {
          delimiter: ' to ',
          blocks: [99, 99],
          uppercase: true,
        },
      },
      fields: [
        {
          key: 'id',
          label: '#',
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          key: 'business_name',
          label: 'Business Name',
          sortable: true,
        },
        {
          key: 'package.package_name',
          label: 'Packages',
          sortable: true,
        },
        {
          key: 'start_date',
          label: 'Start Date',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'end_date',
          label: 'End Date',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'package.order_limit',
          label: 'Order Count',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'subscription_status',
          label: 'Subscription Status',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'created_at',
          label: 'Subscribed At',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
      ],
      rows: [],
      json_fields: {
        'Business name': 'tenant.name',
        Packages: 'package.package_name',
        'Started Date': 'start_date',
        'End Date': 'end_date',
        'Order Count': 'package.order_limit',
        'Subscription Status': 'subscription_status',
        'Subscribed At': {
          field: 'created_at',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
      },
      subscriptionStatus: [
        {
          key: 'active',
          name: 'ACTIVE',
        },
        {
          key: 'deactivate',
          name: 'DEACTIVATE',
        },
        {
          key: 'cancelled',
          name: 'CANCELLED',
        },
        {
          key: 'expired',
          name: 'EXPIRED',
        },
      ],
      packageOptions: [],
      clientOptions: [],
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchSubscriptionList()
      },
    },
    page() {
      this.getSubscriptions()
    },
    perPage() {
      this.getSubscriptions()
    },
  },
  mounted() {
    this.getSubscriptionsforFilter()
    this.getClientList()
  },
  methods: {
    formatDate(date) {
      return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : ''
    },
    updateDateRange(filterKey, newDateRange) {
      if (newDateRange.startDate && newDateRange.endDate) {
        this.filters[filterKey] = {
          startDate: moment(newDateRange.startDate).toISOString(),
          endDate: moment(newDateRange.endDate).toISOString(),
        }
      } else {
        this.filters[filterKey] = {
          startDate: null,
          endDate: null,
        }
      }
    },
    buildFilterQuery() {
      const filterEntries = Object.entries(this.filters)
      const queryParts = filterEntries
        .filter(([value]) => {
          // Filter out empty or null values
          if (typeof value === 'object' && value.startDate && value.endDate) {
            return true
          }
          return value
        })
        .map(([key, value]) => {
          // Handle date range filters
          if (typeof value === 'object' && value.startDate && value.endDate) {
            return `&filter[${key}]=${value.startDate} to ${value.endDate}`
          }
          return `&filter[${key}]=${value}`
        })

      return queryParts.join('')
    },
    async getSubscriptions() {
      try {
        const filterQuery = this.buildFilterQuery()
        const { data } = (await SubscriptionRepository.getSubscriptions(this.page, filterQuery, this.perPage, this.sort))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getSubscriptionsNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        // this.filterQuery = this.__refineDateQuery(this.filterQuery, ['filter[created_at]'])
        const { data } = (await SubscriptionRepository.getSubscriptionsNoPagination())
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async getSubscriptionsforFilter() {
      try {
        const { data } = (await SubscriptionRepository.getSubscriptionsNoPagination())
        this.packageOptions = data.data.map(pkg => ({
          id: pkg.id,
          name: pkg.package.package_name,
        }))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getClientList() {
      this.loading = true
      try {
        const { data } = await clientRepository.getClientListForDropdown()
        this.clientOptions = data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    openPackageListModal(id) {
      this.$refs.packageListModal.openModal(id)
    },
    filterQueryUpdate() {
      this.getSubscriptions()
    },
    handleChangePage(page) {
      this.page = page
      this.getSubscriptions()
    },
    changePage(value) {
      this.perPage = value
    },
    onClickRefresh() {
      this.getSubscriptions()
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
  },
}
</script>
<style lang="scss">
@import '../@core/scss/vue/libs/vue-select.scss';
@import '../@core/scss/vue/libs/vue-daterangepicker.scss';
.dropdown .dropdown-menu {
  max-height: 135px;
  overflow-y: auto;
}
</style>
